import React, { PropsWithChildren, ReactElement } from "react";
import { Link } from "gatsby";
import routes from "../../../routes";
import { IItems } from "../../../pages/site-map"

// #region Interfaces
interface SiteMapItemProps {
    item: IItems;
}
// #endregion

const SiteMapItem = (
    props: PropsWithChildren<SiteMapItemProps>
): ReactElement => {
    const { uid, type } = props.item.nav_link;
    let link;

    if (type !== "policies") {
        link = uid ? `/${type}/${uid}` : routes[type];
    } else {
        link = uid ? `/${uid}` : routes[type];
    }

    // #region JSX
    return (
        <li>
            <Link to={link}>
                {props.item.nav_link_label[0].text}
            </Link>
        </li>
    );
};

export default SiteMapItem;
