import React, { PropsWithChildren, ReactElement } from "react";
import useWindowWidth, { heroDesktopMinWidth } from "../../hooks/useWindowWidth";
import routes from "../../routes"
import getNavData from "../../components/Layout/navData"
import styles from "./SiteMap.module.scss"
import Helmet from "../../components/Helmet";
import Hero from "../../components/shared/HeroStaticPages";
import SiteMapItem from "../../components/shared/SiteMapItem"
import { graphql, Link } from "gatsby";

interface ITermTexts {
    type?: string,
    text?: string,
    spans?: any[]
}

interface INavLink {
    type: string,
    slug: string,
    uid: string
}

interface IPrimary {
    nav_item_link: null,
    nav_item_label: Array<ITermTexts>
}
export interface IItems {
    nav_link: INavLink,
    nav_link_label: Array<ITermTexts>
}

interface INavItem {
    items: Array<IItems>,
    primary: IPrimary,
    slice_type: string
}
const SiteMap = (props: PropsWithChildren<any>): ReactElement => {

    const navData = getNavData();

    let singles: Array<INavItem> = new Array<INavItem>();
    let multiples: Array<INavItem> = new Array<INavItem>();

    navData.forEach((item: INavItem) => {
        if (item.slice_type === "nav_item_multiple") {
            multiples.push(item);

            return;
        }

        singles.push(item);
    });

    //The privacy policies do not go in the general navigation, so we have to add them as a multiple navigation object
    let multipleTermsItems: INavItem = {
        items: [{
            nav_link: {
                slug: "corporate-wellness",
                type: "policies",
                uid: "corporate-wellness"
            },
            nav_link_label: [{
                text: "Corporate Wellness",
            }]
        },
        {
            nav_link: {
                slug: "gym-members-policy",
                type: "policies",
                uid: "gym-members-policy"
            },
            nav_link_label: [{
                text: "Gym Membership Policy",
            }]
        },
        {
            nav_link: {
                slug: "privacy-policy",
                type: "policies",
                uid: "privacy-policy"
            },
            nav_link_label: [{
                text: "Privacy Policy",
            }]
        },
        {
            nav_link: {
                slug: "terms-of-service",
                type: "policies",
                uid: "terms-of-service"
            },
            nav_link_label: [{
                text: "Terms of Service",
            }]
        }],
        primary: {
            nav_item_label: [{
                text: "Policies",
            }],
            nav_item_link: null
        },
        slice_type: "nav_item_multiple"
    }

    multiples.push(multipleTermsItems)

    return (
        <>
            <Helmet
                title={"Site Map"}
                description={"Welcome, use the Site Map to find your way."}
            >
                <meta name="robots" content="noindex"/>
            </Helmet>
            <main>
                <nav className={styles.siteMap}>
                    <div className={styles.siteMapWrapper}>
                        {
                            multiples?.map((item: INavItem, i: number) => (
                                <div className={styles.siteMapItemsWrapper} key={`multiple-${i}`}>
                                    <h5 className={styles.navItemCategory}>{item.primary.nav_item_label[0].text}</h5>
                                    <ul className={styles.navItemList}>
                                        {
                                            item.items.map((item2: IItems, i: number) =>
                                                <SiteMapItem key={`$multiple-item-${i}`} item={item2} />
                                            )
                                        }
                                    </ul>
                                </div>
                            ))
                        }
                        <div className={styles.siteMapItemsWrapper}>
                            {
                                singles?.map((item: INavItem, i: number) =>
                                    <Link key={`single-${i}`} to={routes[item.primary.nav_item_link.type]}>
                                        <div className={styles.navItemCategory}>{item.primary.nav_item_label[0].text}
                                            <span>
                                                <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M10.7354 3.14709C10.8167 2.74092 10.5533 2.3458 10.1471 2.26457L3.52817 0.940782C3.122 0.859548 2.72688 1.12296 2.64564 1.52913C2.56441 1.9353 2.82782 2.33042 3.23399 2.41165L9.11748 3.58835L7.94078 9.47183C7.85954 9.878 8.12296 10.2731 8.52913 10.3544C8.9353 10.4356 9.33042 10.1722 9.41165 9.76601L10.7354 3.14709ZM1.41603 9.62404L10.416 3.62404L9.58397 2.37596L0.583975 8.37596L1.41603 9.62404Z" />
                                                </svg>
                                            </span>
                                        </div>
                                    </Link>
                                )
                            }
                        </div>
                    </div>
                </nav>
            </main>
        </>
    );
};




export default SiteMap;
